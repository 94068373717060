/* eslint-disable no-use-before-define */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { Field, Formik, Form } from 'formik';
import { bool, func } from 'prop-types';
import { useNavigate } from 'react-router-dom';
// import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import backArrowIcon from '../../assets/img/ic_back-arrow.svg';
import * as assetAction from '../../redux/actions/assetsInfoAction';
import * as utilityInfoAction from '../../redux/actions/utilityInfoAction';
import AppTags from '../../components/AppTags/AppTags';
import AppInputGroup from '../../components/AppFormComponents/AppInputGroup';
// import AppRadio from '../../components/AppFormComponents/AppRadio';
// import AppInput from '../../components/AppFormComponents/AppInput';
import AppDropdown from '../../components/AppFormComponents/AppDropdown';

const COURSES_CATEGORIES = [
  `Import`,
  `Export`,
  `Sanctions`,
  `Miscellneous`,
  `Security & Trade`,
  `Corruption & Antiboycott`
];
const assetTypes={
  "images":1,
  "videos":2,
  "audios":3,
  "gif":5
}
// const filterValidation = Yup.object({
//   otherTags: Yup.string().when('adminTags', (tags) => {
//     return tags.includes('others')
//       ? Yup.string().required('Other tags is required')
//       : Yup.string();
//   }),
//   clientAssetRights: Yup.string().when('rights', (rights) => {
//     return rights === 'Client Assets'
//       ? Yup.string().required('This is required')
//       : Yup.string();
//   })
// });
const getAssetTypes = ['images', 'videos', 'audios', 'topicVideos', 'gif'];
const AdvanceFilters = ({ showFilter, setShowFilter }) => {
  const {
    utilityInfo: { assetType,filterLoad },
    assetsInfo: {
      filters: filtersInfo,
      assetTags,
      adminKeyTags,
      filteredTags,
      clientList,
      rightsData,
      allAsset
    }
  } = useSelector((state) => state);
  const [courseCategory, setCourseCategory] = useState([]);
  const [reset, setReset] = useState(false);
  const [adminTags, setAdminTags] = useState([]);
  const [asset,setAsset]=useState("");
  const [selectedRight,setSelectedRight]=useState("")
  const [allTags, setAllTags] = useState([]);
  const [filteredAssetTag, setFilteredAssettag] = useState(filteredTags);
  const dispatch = useDispatch();
  const [clients, setClients] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [show,setShow]=useState(false);
  const [rights, setRights] = useState([]);
  const [isClientLoaded, setIsClientLoaded] = useState(false);
  const [selectedValues, setSelectedValues] = useState();
  const navigate = useNavigate();
  const [olderAsset, setolderAsset] = useState('');
  const formRef = useRef();
  const resetForm = () => {
    const assetDetailType = olderAsset || assetType;
    const assetValue = getAssetTypes[assetDetailType - 1];
    const formikContext = formRef.current;
    const formValues = formikContext.initialValues;
    setCourseCategory([]);
    setAdminTags([]);
    formValues.assetTypeFilter = assetValue;
    formValues.rights = '';
    formikContext.resetForm({ values: formValues });
    const fieldData = {
      ...filtersInfo,
      ...formValues,
      assetTypeFilter: assetDetailType
    };
    setShow(false);
    setSelectedValues();
    setAsset("");
    setSelectedRight("");
    dispatch({
      type: "SET_FILTERS_TAGS",
      payload: []
    })
    dispatch({ type: 'FILTER_SHOW', payload: false })
    dispatch(utilityInfoAction.updateAssetType(assetDetailType));
    navigate(`/${assetValue}`);
    dispatch(assetAction.advanceFilter(fieldData));
    setShowFilter(false);
  };
  useEffect(() => {
    if (adminKeyTags) {
      const tagData = [...new Set(adminKeyTags.concat(assetTags))];
      setAllTags(tagData);
    }
  }, [adminKeyTags]);

  useEffect(() => {
    setFilteredAssettag(filteredTags);
    setAdminTags(filteredTags);
  }, [filteredTags]);

  useEffect(() => {
    if (rightsData.length > 0) setRights(rightsData);
    // eslint-disable-next-line no-console
  }, [rightsData]);
  useEffect(() => {
    if (
      (selectedValues?.rights === 'Client Assets' ||
        selectedValues?.rights[0] === 6) &&
      clients.length === 0
    ) {
      setClients(clientList);
    }
  }, [selectedValues?.rights, isClientLoaded]);
  // useEffect(() => {
  //   const assetValue = getAssetTypes[assetType - 1];
  //   formRef?.current?.setFieldValue('assetTypeFilter', assetValue);
  // }, [assetType]);
  // if (!assetType) return null;
  // const assetsFilter = getAssetTypes[assetType ? assetType - 1 : 0];

  const handleCloseAdminTags = (index) => {
    const existingTag = adminTags[index];
    const removedTags = adminTags.filter((tag, i) => i !== index);
    const removeFromFilteredTag = filteredAssetTag.filter(
      (fTag) => existingTag !== fTag
    );
    setFilteredAssettag(removeFromFilteredTag);
    setAdminTags(removedTags);
  };
  const handleClearFilter = () => {
    setAdminTags([]);
  };
  const handleAssetTypeChange=(e)=>{
    setShow(false)
    setAsset(e.target.value)
    if(allAsset[assetTypes[e.target.value]]?.length){
      setShow(true)
    }
    else{
      dispatch({type:"FILTER_LOAD",payload:true})
      dispatch(
        assetAction.getAssetsByType({
          type: assetTypes[e.target.value],
          prevTag: assetTags
        })
      );
    }
    formRef?.current?.setFieldValue('assetTypeFilter', e.target.value);
  }

  useEffect(()=>{
    if(allAsset[assetTypes[asset]]?.length){
      dispatch({type:"FILTER_LOAD",payload:false})
      setShow(true)
    }
  },[allAsset])


  const handleCloseCourseCategory = (index) => {
    const removedcourseCategory = courseCategory.filter(
      (course, i) => i !== index
    );
    setCourseCategory(removedcourseCategory);
  };
  const handleDropdownChange = (values, e) => {
    // eslint-disable-next-line no-param-reassign
    values.rights = [Number(e?.target?.value)];
    setSelectedValues({...values,rights:[Number(e?.target?.value)]}); // Update form field with the selected value
    setSelectedRight(e.target.value)
    setIsClientLoaded(false);
    if (e?.target?.value === 'Client Assets' || Number(e?.target?.value) === 6)
      setIsClientLoaded(true);
  };
  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        sortBy: 'best',
        assetTypeFilter: asset,
        // tags: [],
        adminTags: filtersInfo?.adminTags || [],
        otherTags: '',
        courseCategory: filtersInfo?.courseCategory ||[],
        rights: [],
        clientAssetRights: '',
        globalAsset: 0
      }}
      validate={(values)=>{
        const errors = {};

        // Validate 'otherTags'
        if (values?.adminTags && values?.adminTags.includes('others')) {
          if (!values.otherTags || values.otherTags.trim() === '') {
            errors.otherTags = 'Other tags is required';
          }
        }
      
        // Validate 'clientAssetRights'
        if (values?.rights[0] === 6) {
          if (!values.clientAssetRights || values.clientAssetRights.trim() === '') {
            errors.rights = 'This is required';
          }
        }
        return errors;
      }}
      onSubmit={async (values) => {
        let submitValue = {};
        if (
          values?.rights === 'Client Assets' ||
          Number(values?.rights[0]) === 6
        ) {
          submitValue = {
            ...values,
            rights: Array.isArray(values?.rights)
              ? [
                  ...values.rights,
                  ...(Array.isArray(values?.clientAssetRights)
                    ? values.clientAssetRights
                    : [values?.clientAssetRights])
                ]
              : [
                  values?.rights,
                  ...(Array.isArray(values?.clientAssetRights)
                    ? values.clientAssetRights
                    : [values?.clientAssetRights])
                ]
          };
        } else {
          submitValue = { ...values };
        }
        if (values.rights) {
          if (Array.isArray(values?.rights)) {
            if (!values.rights.includes(0) && values?.globalAsset) {
              values.rights.push(0);
            }
            values.rights.push(
              values?.clientAssetRights || values?.clientAsset || ""
            );
          } else {
            values.rights = [
              values?.clientAssetRights || values?.clientAsset || ""
            ]; // Convert to array and push 0
            if (values?.globalAsset) values.rights.push(0);
          }
        } else if (values?.globalAsset) values.rights.push(0);
        try {
          const filterassetType =
            getAssetTypes.findIndex(
              (assets) => submitValue.assetTypeFilter === assets
            ) + 1;
          // let adminTagsFilter = values.adminTags.concat(
          //   filtersInfo?.adminTags || []
          // );

          const adminTagsFilter = [
            ...new Set(adminTags.concat(filteredAssetTag))
          ];
          const fieldData = {
            ...filtersInfo,
            // ...values,
            ...submitValue,
            adminTags: adminTagsFilter,
            // ...submitValue,
            assetTypeFilter: filterassetType
          };
          if (!olderAsset) setolderAsset(assetType);
          dispatch(utilityInfoAction.updateAssetType(assetTypes[asset]));
          navigate(`/${asset}`);
          dispatch(assetAction.advanceFilter(fieldData));
          setShowFilter(false);
          values.clientAssetRights='';
          values.rights=[];
          setSelectedRight('')
          setSelectedValues({...values})
          setCourseCategory([])
          dispatch({ type: 'FILTER_SHOW', payload: false })
        } catch (error) {
          console.log(error);
        }
      }}
    >
      {({ setFieldValue, values, handleSubmit, errors, touched }) => (
        <Form>
          <div className="filter-drawer-wrapper">
            <div
              className={`filter-drawer offcanvas offcanvas-end ${
                showFilter && 'show'
              }`}
              tabIndex="-1"
              id="searchFilter"
              aria-labelledby="searchFilterLabel"
            >
              <div className="offcanvas-header">
                <button
                  type="button"
                  className="unset-all text-reset"
                  aria-label="Close"
                  onClick={() => {
                    dispatch({ type: 'FILTER_SHOW', payload: false })
                    setShowFilter(!showFilter, setReset(true));
                    values.clientAssetRights='';
                    values.rights=[];
                    setSelectedRight('')
                    setSelectedValues({...values})
                  }}
                >
                  <img src={backArrowIcon} alt="" />
                </button>
                <h4 id="searchFilterLabel">Advance Filters</h4>
              </div>
              <div className="offcanvas-body">
                <div className="mb-30">
                  <label className="form-label">Asset Type</label>
                  <div>
                    <label className="custom-radio-container mr-30">
                      Image
                      <Field
                        type="radio"
                        name="assetTypeFilter"
                        value="images"
                        checked={asset === "images"}
                        onChange={handleAssetTypeChange}
                      />
                      <span className="checkmark" />
                    </label>
                    <label className="custom-radio-container mr-30">
                      Gif
                      <Field type="radio" 
                      name="assetTypeFilter" 
                      value="gif"            
                      checked={asset === "gif"}
                      onChange={handleAssetTypeChange} />
                      <span className="checkmark" />
                    </label>
                    <label className="custom-radio-container mr-30">
                      Video
                      <Field
                        type="radio"
                        name="assetTypeFilter"
                        value="videos"
                        checked={asset === "videos"}
                        onChange={handleAssetTypeChange}
                      />
                      <span className="checkmark" />
                    </label>
                    <label className="custom-radio-container">
                      Audio
                      <Field
                        type="radio"
                        name="assetTypeFilter"
                        value="audios"
                        checked={asset === "audios"}
                        onChange={handleAssetTypeChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
                  {
                    // eslint-disable-next-line react/no-unknown-property
                    filterLoad && <div className='w-100 d-flex h-25 justify-content-center'>
                      <div className="loader w-25"></div>
                    </div>
                  }
                  {
                    show && <>           
                <div className="mb-30">
                  <label className="form-label">Tags</label>
                  <AppInputGroup
                    id="adminTags"
                    label="Keywords"
                    placeholder="Enter keywords"
                    button="ADD"
                    name="adminTags"
                    options={allTags}
                    reset={reset}
                    handleClick={(value) => {
                      const adminTagValue = [
                        ...new Set([...adminTags, ...value])
                      ];
                      setAdminTags(adminTagValue);
                      setFieldValue('adminTags', adminTagValue);
                    }}
                  />
                  <AppTags
                    tags={adminTags}
                    hasClear
                    closable
                    clearFilter={handleClearFilter}
                    handleClick={(index) => handleCloseAdminTags(index)}
                    classes="searchTags"
                  />
                </div>
               
                <AppInputGroup
                  id="courseCategory"
                  label="Course Category"
                  placeholder="Select Course Category"
                  button="ADD"
                  classes="mt-30"
                  name="courseCategory"
                  options={COURSES_CATEGORIES}
                  reset={reset}
                  handleClick={(value) => {
                    const courseCategoryValue = [
                      ...new Set([...courseCategory, ...value])
                    ];
                    setCourseCategory(courseCategoryValue);
                    setFieldValue('courseCategory', courseCategoryValue);
                  }}
                />
                <AppTags
                  tags={courseCategory}
                  closable
                  handleClick={(index) => handleCloseCourseCategory(index)}
                />
                <div>
                  <div className="mt-30">
                    <div className="d-flex align-items-center">
                      <div className="w-50 ">
                        <AppDropdown
                          name="rights"
                          label="Rights"
                          value={selectedRight}
                          options={rights}
                          onChange={(e) => handleDropdownChange(values, e)}
                          classes="form-group"
                        />
                      </div>
                      <div className="w-50 pl-30 pt-20">
                        <input
                          type="checkbox"
                          id="globalAsset"
                          name="globalAsset"
                          value="global"
                          onChange={(e) =>
                            setFieldValue('globalAsset', e.target.checked)
                          }
                          checked={values.globalAsset}
                        />
                        <label htmlFor="globalAsset" className="ms-2">
                          Global Asset
                        </label>
                      </div>
                    </div>
                    {(selectedValues?.rights === 'Client Assets' ||
                      Number(selectedValues?.rights[0]) === 6) && (
                      <div className="d-flex align-items-start mt-20 mb-30">
                        <div className="w-100">
                          {clients.length > 0 ? (
                            <select
                              name="clientAsset"
                              className="form-select"
                              onChange={(e) =>{
                                setFieldValue(
                                  'clientAssetRights',
                                  e.target.value
                                )
                                setSelectedValues({...values,clientAssetRights: e.target.value})}
                              }
                              value={selectedValues?.clientAssetRights}
                            >
                              <option value="" disabled>
                                Select Client
                              </option>
                              {clients.map((client) => (
                                <option key={client._id} value={client._id}>
                                  {client.clientname}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <p>Loading clients...</p>
                          )}
                        </div>
                      </div>
                    )}
                    {touched?.rights && errors.rights ? (
                      <div className="error">{errors.rights}</div>
                    ) : null}
                  </div>
                </div>
                    </>
                  }
              </div>

              {show && 
              <div className="offcanvas-footer">
                <button
                  type="button"
                  className="btn btn-outline mr-30"
                  onClick={resetForm}
                >
                  RESET FILTER
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  APPLY FILTER
                </button>
              </div>
              }
            </div>
            <div className={`filter-drawer-fade ${showFilter && 'show'}`} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

AdvanceFilters.propTypes = {
  showFilter: bool.isRequired,
  setShowFilter: func.isRequired
};
export default AdvanceFilters;
