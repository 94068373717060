import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tabImagesIcon from '../../assets/img/ic_tab-images.svg';
import tabVideosIcon from '../../assets/img/ic_tab-videos.svg';
import tabAudioIcon from '../../assets/img/ic_tab-audio.svg';
import topicVideoIcon from '../../assets/img/ic_topic-videos.svg';
import AppButton from '../AppButtons/AppButton';
import * as utilityInfoAction from '../../redux/actions/utilityInfoAction';
import * as assetAction from '../../redux/actions/assetsInfoAction';
import { LEGACYPERMISSION } from '../../utils/constants';

function NavigationButtons() {
  const userInfo = useSelector((state) => state.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    utilityInfo: { assetType },
    assetsInfo: {
      filters: filtersInfo,
      topicVideoSearchList: { archiveCrs }
    }
  } = useSelector((state) => state);

  const handleAssetChange = (type) => {
    dispatch(utilityInfoAction.updateAssetType(type));
    dispatch(utilityInfoAction.setloader(true));
    if (type === 1) navigate('/');
    if (type === 2) navigate('/videos');
    if (type === 3) navigate('/audios');
    if (type === 4) navigate('/topicVideos');
    if (type === 5) navigate('/gif');
    dispatch(assetAction.filterAssetList(type));
    if (!archiveCrs?.length > 0) dispatch(assetAction.setArchiveCourses());
    setTimeout(() => {
      dispatch(utilityInfoAction.setloader(false));
    }, 500);
  };

  useEffect(() => {
    if (assetType !== 4) {
      const filtersInfoUpate = {
        ...filtersInfo,
        assetTypeFilter: assetType
      };
      dispatch(assetAction.advanceFilter(filtersInfoUpate));
    }
  }, [navigate]);

  return (
    <div className="toolbar-tabs-wrapper">
      <div className="btn-group" role="group" aria-label="Basic example">
        <AppButton
          icon={tabImagesIcon}
          iconBefore
          name="Images"
          classes={assetType === 1 && 'active'}
          handleClick={() => handleAssetChange(1)}
        />
        <AppButton
          icon={tabImagesIcon}
          iconBefore
          name="Gif"
          classes={assetType === 5 && 'active'}
          handleClick={() => handleAssetChange(5)}
        />
        <AppButton
          icon={tabVideosIcon}
          iconBefore
          name="Videos"
          classes={assetType === 2 && 'active'}
          handleClick={() => handleAssetChange(2)}
        />
        <AppButton
          icon={tabAudioIcon}
          iconBefore
          name="Audio"
          classes={assetType === 3 && 'active'}
          handleClick={() => handleAssetChange(3)}
        />
        {LEGACYPERMISSION.includes(userInfo?.user?.email) && (
          <AppButton
            icon={topicVideoIcon}
            iconBefore
            name=" Legacy Videos"
            classes={assetType === 4 && 'active'}
            handleClick={() => handleAssetChange(4)}
          />
        )}
      </div>
    </div>
  );
}

export default NavigationButtons;
